<template>
  <div>
    <SearchPanel
      flex
      show-btn
      size="mini"
      @getList="onSearch"
    >
      <div>
        <p>
          下单时间
        </p>
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        />
      </div>
      <div>
        <p>
          订货单编号
        </p>
        <el-input
          v-model.trim="supplyNo"
          placeholder="请输入订货单编号"
          size="mini"
          clearable
        />
      </div>
      <div v-if="isMemberStore">
        <p>
          关联单号
        </p>
        <el-input
          v-model.trim="orderNo"
          placeholder="请输入关联单号"
          size="mini"
          clearable
        />
      </div>
      <div v-if="isMemberStore">
        <p>
          供应商
        </p>
        <el-input
          v-model.trim="supplierName"
          placeholder="请输入供应商"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          订货单状态
        </p>
        <el-select
          v-model="notDeliveryType"
          size="mini"
          placeholder="请选择订货单状态"
          filterable
          clearable
          @change="getSearchList()"
        >
          <el-option
            label="全部"
            :value="''"
          />
          <el-option
            label="待发货"
            :value="4"
          />
          <el-option
            label="待收货"
            :value="6"
          />
          <el-option
            label="交易成功"
            :value="7"
          />
          <el-option
            label="交易关闭"
            :value="8"
          />
        </el-select>
      </div>

      <div>
        <p>
          是否全部发货
        </p>
        <el-select
          v-model="isAllDelivery"
          size="mini"
          placeholder="请选择是否全部发货"
          filterable
          clearable
          @change="getSearchList()"
        >
          <el-option
            v-for="item in isAllDeliveryOptions"
            :key="item.value + 'isAllDelivery'"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>

      <div>
        <p>
          售后状态
        </p>
        <el-select
          v-model="refundStatus"
          size="mini"
          placeholder="请选择售后状态"
          filterable
          clearable
          @change="getSearchList()"
        >
          <el-option
            v-for="item in refundStatusOptions"
            :key="item.value + 'refundStatus'"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>

      <div>
        <p>
          结算状态
        </p>
        <el-select
          v-model="settlementStatus"
          size="mini"
          placeholder="请选择结算状态"
          filterable
          clearable
          @change="getSearchList()"
        >
          <el-option
            v-for="item in settlementStatusOptions"
            :key="item.value + 'settlementStatus'"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>

      <div>
        <p>
          商品ID
        </p>
        <el-input
          v-model.trim="goodsId"
          placeholder="请输入完整商品ID"
          size="mini"
          clearable
          type="number"
        />
      </div>

      <div>
        <p>
          商品名称
        </p>
        <el-input
          v-model.trim="goodsName"
          placeholder="请输入关键字"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          收件人名称
        </p>
        <el-input
          v-model.trim="receiverName"
          placeholder="请输入完整收件人名称"
          size="mini"
          clearable
        />
      </div>

      <div>
        <p>
          收件人手机号
        </p>
        <el-input
          v-model.trim="mobile"
          placeholder="请输入收件人手机号"
          size="mini"
          clearable
        />
      </div>
      <div v-if="isMemberStore">
        <p>
          关联平台
        </p>
        <el-select
          v-model="projectCode"
          size="mini"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="(item,i) in platformConfigList"
            :key="i"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="resetSearch"
      >
        重 置
      </el-button>
      <el-button
        slot="button-right"
        type="primary"
        size="mini"
        :loading="downLoading"
        @click="download"
      >
        导 出
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="loading"
      :data="tableData"
      class="tableBox"
      default-expand-all
      :cell-style="{
        background: '#FAFAFA'
      }"
    >
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-table
            :data="row.supplyOrderGoodsVOList"
            border
          >
            <el-table-column
              label="商品信息"
              width="300"
            >
              <template slot-scope="{row}">
                <div class="goods-card">
                  <img :src="row.goodsImg" />
                  <div class="content">
                    <p>{{ row.goodsName }}</p>
                    <p class="sku">
                      ID:{{ row.goodsId }}
                    </p>
                    <p class="sku">
                      规格：{{ row.skuName }}
                    </p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="订货价"
              prop="purchasePrice"
            />
            <el-table-column
              label="订货数"
              prop="purchaseCount"
            />
            <el-table-column
              label="商品金额"
              prop="purchaseAmount"
            />
            <el-table-column
              label="状态"
              prop="statusExplain"
            />
            <el-table-column
              label="售后状态"
              prop="refundStatusExplain"
            />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="订货单号"
        min-width="100"
      >
        <template slot-scope="{row}">
          <span>{{ row.supplyNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="订货单状态"
        width="120"
      >
        <template slot-scope="{row}">
          <span>{{ row.statusExplain }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="订货金额（元）"
        width="120"
      >
        <template slot-scope="{row}">
          <span>{{ row.purchaseAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="收件人"
        min-width="100"
      >
        <template slot-scope="{row}">
          <span>{{ row.receiverName }}</span>
          <span>  {{ row.receiverMobile }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isMemberStore"
        label="供应商"
      >
        <template slot-scope="{row}">
          <span>{{ row.supplierName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isMemberStore"
        label="关联单号"
        min-width="100"
      >
        <template slot-scope="{row}">
          <span>{{ row.orderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isMemberStore"
        prop="projectCodeExplain"
        label="关联平台"
        min-width="100"
      />
      <el-table-column
        label="下单时间"
        min-width="100"
      >
        <template slot-scope="{row}">
          <span>{{ formatTime(row.time) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="结算状态"
        width="100"
      >
        <template slot-scope="{row}">
          <span>{{ row.settlementStatusExplain }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="170"
      >
        <template slot-scope="{row}">
          <el-button
            size="mini"
            type="primary"
            @click="handleDetail(row.id)"
          >
            查看详情
          </el-button>
          <!-- 判断是不是优选店铺 -->
          <el-button
            v-if="!isMemberStore"
            size="mini"
            type="primary"
            :disabled="!row?.canDeliver"
            @click="onDeliverGoods(row)"
          >
            发货
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <!-- 订单发货弹窗 -->
    <DeliverOrderDialog
      v-model="showDeliverModel"
      :order-data="orderData"
      type="add"
      @success="deliverSuccess"
    />
  </div>
</template>

<script setup>
import {
  ref, getCurrentInstance, onMounted,
} from 'vue';
import { set } from 'lodash';
import dayjs from 'dayjs';
import DeliverOrderDialog from './components/deliver-order-dialog.vue';
import { downloadFile } from '@/utils/download';
import { platformConfigList } from '@/utils/projectConfig';

const app = getCurrentInstance().proxy;
const loading = ref(false);
const beginTime = ref(''); // 查询-下单时间
const endTime = ref('');
const goodsId = ref(''); // 查询-商品ID
const goodsName = ref(''); // 查询-商品名称
const mobile = ref(''); // 查询-收件人手机号
const orderNo = ref(''); // 查询-关联编号
const supplyNo = ref(''); // 查询-订单编号
const supplierName = ref(''); // 查询-供应商名称
const receiverName = ref(''); // 查询-收件人名称
const refundStatus = ref(''); // 查询-售后状态
const logisticNo = ref(''); // 查询-快递单号
const notDeliveryType = ref(''); // 查询-订货单状态
const projectCode = ref('');
// 结算状态
const settlementStatus = ref('');
// 是否全部发货
const isAllDelivery = ref('');
// 结算状态数据
const settlementStatusOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '未结算',
    value: 0,
  },
  {
    label: '待结算',
    value: 1,
  },
  {
    label: '可提现',
    value: 2,
  },
  {
    label: '待打款',
    value: 3,
  },
  {
    label: '已提现',
    value: 4,
  },
];
// 是否全部发货的选择数据
const isAllDeliveryOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '是',
    value: 1,
  },
  {
    label: '否',
    value: 0,
  },
];
// 售后状态
const refundStatusOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '待商家退款',
    value: 1,
  },
  {
    label: '退款中',
    value: 2,
  },
  {
    label: '（商家拒绝退款）待用户处理',
    value: 3,
  },
  {
    label: '平台介入中',
    value: 4,
  },
  {
    label: '退款成功',
    value: 5,
  },
  {
    label: '退款关闭',
    value: 6,
  },
  {
    label: '待商家处理',
    value: 11,
  },
  {
    label: '待买家退货',
    value: 12,
  },
];
const downLoading = ref(false);
const currentPage = ref(1); // 分页
const pageSize = ref(10);
const dateRange = ref([new Date().setMonth(new Date().getMonth() - 1), new Date().getTime()]);
const tableData = ref([]);
const count = ref(0);
const selection = ref({});
const currentOrder = ref('');
const showDeliverModel = ref(false);
const orderData = ref(null);
const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));

// 获取搜索参数
const getParams = () => {
  let params = {
    currentPage: currentPage.value,
    pageSize: pageSize.value,
    projectCode: projectCode.value || undefined,
  };
  if (orderNo.value) set(params, 'orderNo', orderNo.value); // 关联编号
  if (supplyNo.value) set(params, 'supplyNo', supplyNo.value); // 订单编号
  if (goodsId.value) set(params, 'goodsId', goodsId.value); //  商品ID
  if (supplierName.value) set(params, 'supplierName', supplierName.value); // 供应商名称
  if (goodsName.value) set(params, 'goodsName', goodsName.value);// 商品名称
  if (mobile.value) set(params, 'receiverMobile', mobile.value);// 收件人手机号
  if (notDeliveryType.value) set(params, 'status', notDeliveryType.value); // 订货单状态
  if (isAllDelivery.value || isAllDelivery.value === 0) set(params, 'isAllDeliver', isAllDelivery.value); // 是否全部发货
  if (settlementStatus.value || settlementStatus.value === 0) set(params, 'settlementStatus', settlementStatus.value); // 结算状态
  if (receiverName.value) set(params, 'receiverName', receiverName.value);//  收件人名称
  if (refundStatus.value) set(params, 'refundStatus', refundStatus.value);// 售后状态
  if (dateRange.value && dateRange.value.length > 0) {
    set(params, 'createBeginTime', dateRange.value[0]);
    set(params, 'createEndTime', dateRange.value[1]);
  }
  return params;
};

const getSearchList = () => {
  let params = getParams();
  loading.value = true;
  app.$axios
    .post(app.$api.supplierOrderOrder.page, params)
    .then((res) => {
      tableData.value = res.data.records;
      count.value = Number(res.data.total);
      loading.value = false;
      selection.value = {};
      currentOrder.value = '';
    })
    .finally(() => {
      loading.value = false;
    });
};

const onSearch = () => {
  // 修改搜索条件时把页码重置为1
  currentPage.value = 1;
  getSearchList();
};

const resetSearch = () => {
  receiverName.value = ''; // 查询-收件人名称
  beginTime.value = '';// 查询-开始时间
  endTime.value = ''; //  查询-结束时间
  goodsId.value = '';// 查询-商品ID
  goodsName.value = '';// 查询-商品名称
  mobile.value = '';// 查询-手机号
  orderNo.value = '';// 查询-关联号
  supplyNo.value = '';// 查询-订单编号
  supplierName.value = '';// 查询-供应商名称
  receiverName.value = '';// 查询-收件人名称
  dateRange.value = [new Date().setMonth(new Date().getMonth() - 1),
    new Date().getTime()];// 查询-时间范围
  refundStatus.value = '';// 查询-售后状态
  logisticNo.value = '';// 查询-物流单号
  notDeliveryType.value = ''; // 查询-订货单状态
  settlementStatus.value = '';// 查询-结算状态
  isAllDelivery.value = '';// 查询-是否全部发货
  projectCode.value = '';
};

const formatTime = (time) => {
  return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
};

const handleDetail = (id) => {
  app.$router.push({
    path: '/supplyChain/purchaseOrder/detail',
    query: {
      orderId: id,
    },
  });
};

const onDeliverGoods = (row) => {
  orderData.value = row;
  showDeliverModel.value = true;
  console.log(row);
};

const deliverSuccess = () => {
  getSearchList();
};

const handleSizeChange = (val) => {
  pageSize.value = val;
  currentPage.value = 1;
  getSearchList();
};

const handleCurrentChange = (val) => {
  currentPage.value = val;
  getSearchList();
};

const download = async () => {
  app.$message.success('正在导出数据，请稍后前往浏览器下载列表查看下载文件');
  let params = getParams();
  downLoading.value = true;
  const res = await app.$axios.post(app.$api.supplierOrderOrder.export, params, { responseType: 'blob' });
  downLoading.value = false;
  downloadFile(res, `订货单导出_${dayjs(new Date()).format('YYYY-MM-DD')}_${dayjs(new Date()).format('HH-mm-ss')}.xls`);
};

onMounted(() => {
  getSearchList();
});
</script>

<style lang="scss" scoped>
.tableBox{
  :deep(.el-table__header){
    .el-table__cell{
      font-weight: bold;
      color: #333;
    }
  }
  :deep(.el-table__row){
    font-size: 12px;
  }
}
.goods-card {
      min-width: 10px;
      width: 100%;
      display: flex;
      img {
        margin-top: 8px;
        width: 88px;
        height: 88px;
      }
      .content {
        padding: 0 12px;
        line-height: 28px;
        .sku{
          color: #736E6E;
        }
      }
    }
</style>

<style scoped>
/* .tableBox >>> .el-table__expand-icon:after {
  content: '';
}
.tableBox >>> .el-table__expand-icon > i {
  display: none;
} */
/* .tableBox >>> .el-table__expand-column {
  border-right: none;
} */
.tableBox >>> .el-table__expanded-cell[class*=cell] {
  padding: 5px 50px !important;
}
</style>
