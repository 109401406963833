var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "SearchPanel",
        {
          attrs: { flex: "", "show-btn": "", size: "mini" },
          on: { getList: _setup.onSearch },
        },
        [
          _c(
            "div",
            [
              _c("p", [_vm._v(" 下单时间 ")]),
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  size: "mini",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "timestamp",
                },
                model: {
                  value: _setup.dateRange,
                  callback: function ($$v) {
                    _setup.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 订货单编号 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入订货单编号",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _setup.supplyNo,
                  callback: function ($$v) {
                    _setup.supplyNo = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "supplyNo",
                },
              }),
            ],
            1
          ),
          _setup.isMemberStore
            ? _c(
                "div",
                [
                  _c("p", [_vm._v(" 关联单号 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入关联单号",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _setup.orderNo,
                      callback: function ($$v) {
                        _setup.orderNo =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "orderNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _setup.isMemberStore
            ? _c(
                "div",
                [
                  _c("p", [_vm._v(" 供应商 ")]),
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入供应商",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _setup.supplierName,
                      callback: function ($$v) {
                        _setup.supplierName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "supplierName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 订货单状态 ")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "请选择订货单状态",
                    filterable: "",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _setup.getSearchList()
                    },
                  },
                  model: {
                    value: _setup.notDeliveryType,
                    callback: function ($$v) {
                      _setup.notDeliveryType = $$v
                    },
                    expression: "notDeliveryType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: "" } }),
                  _c("el-option", { attrs: { label: "待发货", value: 4 } }),
                  _c("el-option", { attrs: { label: "待收货", value: 6 } }),
                  _c("el-option", { attrs: { label: "交易成功", value: 7 } }),
                  _c("el-option", { attrs: { label: "交易关闭", value: 8 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 是否全部发货 ")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "请选择是否全部发货",
                    filterable: "",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _setup.getSearchList()
                    },
                  },
                  model: {
                    value: _setup.isAllDelivery,
                    callback: function ($$v) {
                      _setup.isAllDelivery = $$v
                    },
                    expression: "isAllDelivery",
                  },
                },
                _vm._l(_setup.isAllDeliveryOptions, function (item) {
                  return _c("el-option", {
                    key: item.value + "isAllDelivery",
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 售后状态 ")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "请选择售后状态",
                    filterable: "",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _setup.getSearchList()
                    },
                  },
                  model: {
                    value: _setup.refundStatus,
                    callback: function ($$v) {
                      _setup.refundStatus = $$v
                    },
                    expression: "refundStatus",
                  },
                },
                _vm._l(_setup.refundStatusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value + "refundStatus",
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 结算状态 ")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    placeholder: "请选择结算状态",
                    filterable: "",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _setup.getSearchList()
                    },
                  },
                  model: {
                    value: _setup.settlementStatus,
                    callback: function ($$v) {
                      _setup.settlementStatus = $$v
                    },
                    expression: "settlementStatus",
                  },
                },
                _vm._l(_setup.settlementStatusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value + "settlementStatus",
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 商品ID ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入完整商品ID",
                  size: "mini",
                  clearable: "",
                  type: "number",
                },
                model: {
                  value: _setup.goodsId,
                  callback: function ($$v) {
                    _setup.goodsId = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "goodsId",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 商品名称 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入关键字",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _setup.goodsName,
                  callback: function ($$v) {
                    _setup.goodsName =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "goodsName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 收件人名称 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入完整收件人名称",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _setup.receiverName,
                  callback: function ($$v) {
                    _setup.receiverName =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "receiverName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v(" 收件人手机号 ")]),
              _c("el-input", {
                attrs: {
                  placeholder: "请输入收件人手机号",
                  size: "mini",
                  clearable: "",
                },
                model: {
                  value: _setup.mobile,
                  callback: function ($$v) {
                    _setup.mobile = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "mobile",
                },
              }),
            ],
            1
          ),
          _setup.isMemberStore
            ? _c(
                "div",
                [
                  _c("p", [_vm._v(" 关联平台 ")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        placeholder: "请选择",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _setup.projectCode,
                        callback: function ($$v) {
                          _setup.projectCode = $$v
                        },
                        expression: "projectCode",
                      },
                    },
                    _vm._l(_setup.platformConfigList, function (item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", size: "mini" },
              on: { click: _setup.resetSearch },
              slot: "button-right",
            },
            [_vm._v(" 重 置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                slot: "button-right",
                type: "primary",
                size: "mini",
                loading: _setup.downLoading,
              },
              on: { click: _setup.download },
              slot: "button-right",
            },
            [_vm._v(" 导 出 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _setup.loading,
              expression: "loading",
            },
          ],
          staticClass: "tableBox",
          attrs: {
            data: _setup.tableData,
            "default-expand-all": "",
            "cell-style": {
              background: "#FAFAFA",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-table",
                      {
                        attrs: { data: row.supplyOrderGoodsVOList, border: "" },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "商品信息", width: "300" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("div", { staticClass: "goods-card" }, [
                                      _c("img", {
                                        attrs: { src: row.goodsImg },
                                      }),
                                      _c("div", { staticClass: "content" }, [
                                        _c("p", [
                                          _vm._v(_vm._s(row.goodsName)),
                                        ]),
                                        _c("p", { staticClass: "sku" }, [
                                          _vm._v(
                                            " ID:" + _vm._s(row.goodsId) + " "
                                          ),
                                        ]),
                                        _c("p", { staticClass: "sku" }, [
                                          _vm._v(
                                            " 规格：" +
                                              _vm._s(row.skuName) +
                                              " "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "订货价", prop: "purchasePrice" },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "订货数", prop: "purchaseCount" },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "商品金额", prop: "purchaseAmount" },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "状态", prop: "statusExplain" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "售后状态",
                            prop: "refundStatusExplain",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "订货单号", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.supplyNo))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "订货单状态", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.statusExplain))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "订货金额（元）", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.purchaseAmount))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "收件人", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(row.receiverName))]),
                    _c("span", [_vm._v(" " + _vm._s(row.receiverMobile))]),
                  ]
                },
              },
            ]),
          }),
          _setup.isMemberStore
            ? _c("el-table-column", {
                attrs: { label: "供应商" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [_c("span", [_vm._v(_vm._s(row.supplierName))])]
                      },
                    },
                  ],
                  null,
                  false,
                  101953751
                ),
              })
            : _vm._e(),
          _setup.isMemberStore
            ? _c("el-table-column", {
                attrs: { label: "关联单号", "min-width": "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [_c("span", [_vm._v(_vm._s(row.orderNo))])]
                      },
                    },
                  ],
                  null,
                  false,
                  1576043275
                ),
              })
            : _vm._e(),
          _setup.isMemberStore
            ? _c("el-table-column", {
                attrs: {
                  prop: "projectCodeExplain",
                  label: "关联平台",
                  "min-width": "100",
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "下单时间", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(_setup.formatTime(row.time)))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "结算状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(row.settlementStatusExplain))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _setup.handleDetail(row.id)
                          },
                        },
                      },
                      [_vm._v(" 查看详情 ")]
                    ),
                    !_setup.isMemberStore
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !row?.canDeliver,
                            },
                            on: {
                              click: function ($event) {
                                return _setup.onDeliverGoods(row)
                              },
                            },
                          },
                          [_vm._v(" 发货 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _setup.currentPage,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _setup.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _setup.count,
        },
        on: {
          "size-change": _setup.handleSizeChange,
          "current-change": _setup.handleCurrentChange,
        },
      }),
      _c(_setup.DeliverOrderDialog, {
        attrs: { "order-data": _setup.orderData, type: "add" },
        on: { success: _setup.deliverSuccess },
        model: {
          value: _setup.showDeliverModel,
          callback: function ($$v) {
            _setup.showDeliverModel = $$v
          },
          expression: "showDeliverModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }